import React from "react";
import ImageGallery from "react-image-gallery";
import { Helmet, HelmetProvider } from "react-helmet-async";
import "react-image-gallery/styles/css/image-gallery.css";
import Intenzivna from "../../assets/images/Intezivna/intenzivna2.jpg";
import Intenzivna4 from "../../assets/images/Intezivna/intenzivna4.jpg";
import MedicinskaNjega from "../../assets/images/Intezivna/Med-njega1.jpg";
import Amadeo from "../../assets/images/Intezivna/Amadeo 2.jpg";
import Biodex from "../../assets/images/Intezivna/biodex.jpg";
import Biodex2 from "../../assets/images/Intezivna/biodex2.jpg";
import Intezivna1 from "../../assets/images/Intezivna/intenzivna1.jpg";
import Hod from "../../assets/images/Intezivna/hod.jpg";
//Dom
import Dom1 from "../../assets/images/dom/dom1.jpg";
import Dom2 from "../../assets/images/dom/dom2.jpg";
import Dom3 from "../../assets/images/dom/dom3.jpg";
import Dom4 from "../../assets/images/dom/dom4.jpg";
import Dom5 from "../../assets/images/dom/dom5.jpg";
import Dom6 from "../../assets/images/dom/dom6.jpg";
import Dom7 from "../../assets/images/dom/dom7.jpg";
import Dom8 from "../../assets/images/dom/dom8.jpg";

import Dom12 from "../../assets/images/dom/dom12.jpg";
//rehabilitacija
import Rehabilitacija from "../../assets/images/rehabilitacija/rehabilitacija.jpg";
import Rehabilitacija2 from "../../assets/images/rehabilitacija/rehabilitacija2.jpg";
import Rehabilitacija3 from "../../assets/images/rehabilitacija/rehabilitacija3.jpg";
import Rehabilitacija4 from "../../assets/images/rehabilitacija/rehabilitacija4.jpg";
import Rehabilitacija5 from "../../assets/images/rehabilitacija/rehabilitacija5.jpg";
import Rehabilitacija6 from "../../assets/images/rehabilitacija/rehabilitacija6.jpeg";

const intezivna = [
  {
    original: Intenzivna,
    thumbnail: Intenzivna,
    description: "Vizita",
  },
  {
    original: Intenzivna4,
    thumbnail: Intenzivna4,
    description: "Oprema",
  },
  {
    original: MedicinskaNjega,
    thumbnail: MedicinskaNjega,
    description: "Description 3",
  },
  {
    original: Intezivna1,
    thumbnail: Intezivna1,
    description: "Intezivna",
  },
  {
    original: Hod,
    thumbnail: Hod,
    description: "Intezivna",
  },
  // Add more images here
];

const dom = [
  {
    original: Dom1,
    thumbnail: Dom1,
    description: "Vizita",
  },
  {
    original: Dom2,
    thumbnail: Dom2,
    description: "Oprema",
  },
  {
    original: Dom4,
    thumbnail: Dom4,
    description: "Description 3",
  },
  {
    original: Dom5,
    thumbnail: Dom5,
    description: "Description 3",
  },
  {
    original: Dom6,
    thumbnail: Dom6,
    description: "Description 3",
  },
  {
    original: Dom7,
    thumbnail: Dom7,
    description: "Description 3",
  },
  {
    original: Dom8,
    thumbnail: Dom8,
    description: "Description 3",
  },
  {
    original: Dom12,
    thumbnail: Dom12,
    description: "Description 3",
  },
  {
    original: Dom3,
    thumbnail: Dom3,
    description: "Description 3",
  },
  // Add more images here
];
const rehabilitacija = [
  {
    original: Rehabilitacija3,
    thumbnail: Rehabilitacija3,
    description: "Vjezbe",
  },
  {
    original: Rehabilitacija4,
    thumbnail: Rehabilitacija4,
    description: "Robotska rehbilitacija",
  },
  {
    original: Rehabilitacija5,
    thumbnail: Rehabilitacija5,
    description: "Biodex",
  },
  {
    original: Rehabilitacija6,
    thumbnail: Rehabilitacija6,
    description: "Oprema",
  },
  {
    original: Biodex,
    thumbnail: Biodex,
    description: "Biodex vjezbe",
  },
  {
    original: Biodex2,
    thumbnail: Biodex2,
    description: "Biodex masina",
  },
  {
    original: Amadeo,
    thumbnail: Amadeo,
    description: "Amadeo vjezba",
  },
  {
    original: Rehabilitacija2,
    thumbnail: Rehabilitacija2,
    description: "Rehabilitacija",
  },
  {
    original: Rehabilitacija,
    thumbnail: Rehabilitacija,
    description: "Rehabilitacija",
  },
];

const videos = [
  "https://www.youtube.com/embed/BHk-xbbvcgk",
  "https://www.youtube.com/embed/Fli2H-zDuu8",
  "https://www.youtube.com/embed/QKcBdemFqNA",
  "https://www.youtube.com/embed/RD4GE4AfGZs",
  "https://www.youtube.com/embed/lFBmOXL9Uwk",
  "https://www.youtube.com/embed/tXwfF6FU8xw"
];

export const Galerija = () => {
  return (
    <HelmetProvider>
      <div className=" w-[100%] mx-auto py-8 bg-siva1 mt-20">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Galerija Fmailia</title>
          <link rel="canonical" href="http://mysite.com/example" />
        </Helmet>
        <div className="flex flex-col items-center justify-center space-y-4 md:space-y-0 md:space-x-4 p-4 mb-20">
          <div className="flex flex-col lg:flex-row justify-center lg:px-28 space-y-5 lg:space-y-0 lg:space-x-5">
            <div className="flex flex-col lg:w-1/2 px-5 justify-center">
              <h1 className="text-3xl md:text-4xl xl:text-6xl  lg:text-5xl text-center lg:text-left font-extrabold text-Narandzasta mb-2 xl:mb-5">
                Video sekcija
              </h1>
              <h2 className="text-xl md:text-3xl lg:text-4xl text-center lg:text-left   font-light font-poppins mb-5">
                Naše priče sa rehabilitacije
              </h2>
              <p className="text-gray-700 text-base md:text-lg text-center lg:text-left">
                Naša kolekcija videozapisa pruža jedinstven i inspirativan
                pogled na putovanje i napredak pojedinaca koji su prošli kroz
                rehabilitaciju nakon moždanog udara u našoj Ustanovi. Svaki
                videozapis priča priču o odlučnosti, upornosti i nevjerovatnoj
                hrabrosti naših pacijenata dok se suočavaju s izazovima
                oporavka. Naši stručni timovi terapeuta, medicinskih sestara i
                liječnika posvećeni su pružanju vrhunske skrbi i podrške,
                omogućujući pacijentima da povrate svoje sposobnosti i
                samopouzdanje.
              </p>
            </div>
            <div className="lg:w-1/2 px-5">
              <iframe
                className="w-full h-64 md:h-64 lg:h-96"
                src="https://www.youtube.com/embed/RsHaqUDymek"
                title="Rehabilitation Video"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>
          <div className="bg-siva1 w-full xl:px-20">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-1 lg:gap-5 justify-center">
              {videos.map((video, index) => (
                <div
                  key={index}
                  className="w-full aspect-w-16 aspect-h-9 mt-14"
                >
                  <iframe
                    className="w-full h-72 md:h-80 lg:h-96"
                    src={video}
                    title={`YouTube video ${index + 1}`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
              ))}
            </div>
          </div>
        </div>
<div className="p-5">
        <h1 className="text-3xl md:text-4xl xl:text-6xl  lg:text-5xl text-center font-extrabold text-Narandzasta mb-5">
          Sekcija Slike
        </h1>
        <h2 className="text-xl md:text-2xl lg:text-3xl  text-center font-extralight font-poppins mb-5">
          Sve slike Nastale unnutar nase ustanove
        </h2>
        <div className="">
          <h1 className="text-3xl md:text-4xl xl:text-5xl text-TamnoPlava text-center mb-5 font-bold mt-10">
            Rehabilitacija
          </h1>
          <ImageGallery items={rehabilitacija} />
        </div>
        <h1 className="text-3xl md:text-4xl xl:text-5xl text-TamnoPlava text-center mb-5 font-bold mt-5 ">
          Intezivna
        </h1>
        <ImageGallery items={intezivna} />
        <div className="">
          <h1 className="text-3xl md:text-4xl xl:text-5xl text-TamnoPlava text-center mb-5 mt-5 font-bold">
            Dom
          </h1>
          <ImageGallery items={dom} />
        </div>
        </div>
      </div>
    </HelmetProvider>
  );
};
