import React from "react";
import Slider from "react-slick";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";

import FizikalnaTerapija2 from "../../assets/images/Fizikalna/fizikalnaTerapija2.jpg";
import rehabilitacijaPriroda from "../../assets/images/Fizikalna/rehabilitacijaPriroda.jpg";
import Dom1 from "../../assets/images/dom/dom3.jpg";
import FizikalnaTerapija from "../../assets/images/Fizikalna/fizikalnaTerapija.jpg";
import FizikalnaTerapija1 from "../../assets/images/Fizikalna/fizikalnaTerapija1.jpg";
import Biodex from "../../assets/images/Fizikalna/biodex.jpg";
import Biodex2 from "../../assets/images/Fizikalna/biodex2.jpg";
import Demencija1 from "../../assets/images/Demencija/demencija1.jpg";
import Demencija2 from "../../assets/images/Demencija/demencija2.jpg";
import Demencija4 from "../../assets/images/Demencija/demencija4.jpg";

export const Smjestaj = () => {
  const sliderImages = [
    {
      src: FizikalnaTerapija,
      alt: "Fizikalna terapija",
    },
    {
      src: FizikalnaTerapija2,
      alt: "Fizikalna terapija 2",
    },
    {
      src: Dom1,
      alt: "Dom 1",
    },
    {
      src: Biodex2,
      alt: "Biodex 2",
    },
    {
      src: FizikalnaTerapija1,
      alt: "Fizikalna terapija 1",
    },
    {
      src: Demencija2,
      alt: "Demencija 2",
    },
    {
      src: Demencija4,
      alt: "Demencija 4",
    },
  ];

  const settings = {
    arrows: true,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    prevArrow: <FontAwesomeIcon icon={faArrowLeft} />,
    nextArrow: <FontAwesomeIcon icon={faArrowRight} />,
    responsive: [
      {
        breakpoint: 1280, // Large screens (e.g., desktops)
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024, // Medium screens (e.g., smaller desktops or large tablets)
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768, // Small screens (e.g., tablets)
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600, // Extra small screens (e.g., large smartphones)
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480, // Small smartphones
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  return (
    <HelmetProvider>
      <div className="bg-svijetloPlava1">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Ustanova Familia smjestaj</title>
          <link rel="canonical" href="http://mysite.com/example" />
        </Helmet>

        {/*Jedinica Dom*/}
        <div className="lg:flex flex-row justify-center mt-[95px] p-5 lg:px-14">
          <div className="flex justify-center  w-[100%] md:w-[95%] lg:w-[85%] xl:w-[95%] 2xl:w-[65%] h-[100%] ">
            <img src={rehabilitacijaPriroda} alt="Rehabilitacija priroda" />
          </div>
          <div className="flex flex-col justify-center text-center w-[95%] lg:text-left">
            <h1 className="text-4xl md:text-5xl text-TamnoPlava mb-10 mt-10 font-extrabold">
              Jedinica dom
            </h1>
            <h2 className="text-xl md:text-3xl font-poppins text-black mb-5">
              Dom Familia pruža smještaj i njegu osobama starijim osobama i
              osobama sa neurokognitivnim deficitom (demencijom), uključujući
              Alzheimerovu bolest i vaskularnu demenciju.
            </h2>
            <p className="font-extralight lg:text-[22px]">
              Premještaj u Dom Familia pružajući sigurnost i njegu često je
              odgovor na smanjene funkcionalne sposobnosti u trećoj životnoj
              dobi, bolest, usamljenost, nesigurnost.
            </p>
            <p className="font-extralight lg:text-[22px] mt-5">
              Osiguravamo ugodan boravak s prilagođenim aktivnostima za svakog
              korisnika ili grupu korisnika (grupne vježbe, izlete, kognitivne
              treninge, radno okupacionu terapiju, muziko terapiju itd).
            </p>
          </div>
        </div>

        {/*Jedinica za pojačanu njegu*/}
        <div className="lg:flex flex-row justify-center p-5 lg:px-14">
          <div className="flex justify-center w-[100%] md:w-[95%] lg:w-[85%] xl:w-[95%] 2xl:w-[65%] h-[100%] ">
            <img src={Demencija1} alt="Demencija 1" />
          </div>
          <div className="flex flex-col justify-center text-center w-[95%] lg:text-left">
            <h2 className="text-4xl md:text-5xl align-top text-TamnoPlava mb-10 mt-12 md:mt-0 font-extrabold">
              Jedinica za pojačanu njegu
            </h2>
            <h2 className="text-xl md:text-3xl text-black font-poppins mb-5">
              Naša jedinica pojačanu njegu pacijenata pruža kontinuiranu
              medicinsku njegu za pacijente sa različitim zdravstvenim
              problemima.
            </h2>
            <p className="font-extralight lg:text-[22px]">
              Naš stručni tim medicinskih profesionalaca osigurava da svaki
              pacijent dobije najviši nivo njege.
            </p>
            <p className="font-extralight lg:text-[22px] mt-5">
              Opremljena je naprednom medicinskom opremom i pruža
              visokospecijaliziranu njegu te brzu reakciju u kriznim
              situacijama.
            </p>
          </div>
        </div>

        {/*Jedinica za rehabilitaciju*/}
        <div className="lg:flex justify-center flex-row p-5 lg:px-14">
          <div className="flex justify-center w-[100%] md:w-[95%] lg:w-[85%] xl:w-[95%] 2xl:w-[65%] h-[100%] ">
            <img src={Biodex} alt="Biodex" />
          </div>
          <div className="flex flex-col justify-center text-center w-[100%] lg:text-left">
            <h1 className="text-5xl text-TamnoPlava mb-10 mt-12 md:mt-0 font-extrabold">
              Jedinica za rehabilitaciju
            </h1>
            <h2 className="text-xl md:text-3xl text-black font-poppins mb-5">
              Dom Familia je najbolje rješenje za korisnike koji traže
              rehabilitaciju nakon moždanog udara, srčanog infarkta ili
              implantacije vještačkih proteza.
            </h2>
            <p className="font-extralight lg:text-[22px] mt-5">
              Dom Familia se ponosi svojim toplim i podržavajućim okruženjem,
              gdje svaki korisnik dobija pažnju i brigu koju zaslužuje. Naš cilj
              je da svi pacijenti postignu maksimalan napredak i vrate se svojim
              svakodnevnim aktivnostima sa obnovljenom snagom i samopouzdanjem..
            </p>
            <p className="font-extralight lg:text-[22px] mt-5">
              Posjetite nas i uvjerite se zašto je Dom Familia idealno mjesto za
              vaš oporavak i rehabilitaciju.
            </p>
          </div>
        </div>

        {/* Image slider */}
        <div className="mt-10 flex justify-center items-center sm:px-10 pb-20 overflow-hidden">
          <div className="w-full md:w-[80%] lg:w-[80%] xl:w-[80%]">
            <Slider {...settings}>
              {sliderImages.map((image, index) => (
                <div key={index}>
                  <img
                    src={image.src}
                    alt={image.alt}
                    className="w-[95%] h-[100%] mx-auto"
                  />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </HelmetProvider>
  );
};
